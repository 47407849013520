<template lang="pug">
.translate-feature-tooltip
  .translate-feature-tooltip-header.my-2
    span(@click="close")
      uil-times.cursor-pointer(:size="'20px'")
  .translate-feature-tooltip-img.my-4
    img(:src="require('@/components/Elements/Monks/svgs/monk-translation.svg')")
  .translate-feature-tooltip-content.mt-5
    badge.translate-feature-tooltip-badge.mb-2(:text="$t('new')" type="rounded")
    om-heading.mb-2(h6) {{ $t('translateFeature.title') }}
    om-body-text(bt400sm) {{ $t('translateFeature.description') }}
</template>
<script>
  import Badge from '@/components/Badge.vue';
  import { UilTimes } from '@iconscout/vue-unicons';

  export default {
    components: { UilTimes, Badge },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/variables'
  .translate-feature-tooltip
    padding: 20px 5px
    max-width: 15.5rem
    div
      white-space: normal
    &-header
      display: flex
      align-items: center
      justify-content: flex-end
      position: absolute
      right: 10px
      top: 10px
    &-img
      margin-bottom: -30px
      margin-top: 10px
      display: flex
      justify-content: center
    &-badge
      width: 52px
    &-content
      .body-text
        color: $om-gray-700
</style>
